import React,{useEffect} from 'react'
import './TopNavigation.css'
import {Link} from 'react-router-dom'
import $ from 'jquery'
const TopNavigation = () => {

    useEffect(()=>{
$(document).ready(function(){
    $('.nav .topnav').before().hover(function(){
       $(this).toggleClass('animation')
    })
})
    },[])

    return (
        <div className="nav">
            <div className="topnav">
                <ul className="list">
                    <li className="">
                        <Link to="/">Home</Link>
                    </li>
                    <li>
                        <Link to="/Tickets">Tickets</Link>
                    </li>
                    <li>
                        <Link to="/tables">tables</Link>
                    </li>
                    <li>
                        <Link to="/Social">Social</Link>
                    </li>
                    <li>
                        <Link to="/Result">Result</Link>
                    </li>
                    <li>
                        <Link to="/Fixtures">Fixtures</Link>
                    </li>
                    <li>
                        <Link>Your clube</Link>
                        <ul>
                            <li>
                                <Link to="/Soccer">Soccer</Link>
                            </li>
                            <li>
                                <Link to="/Tennis">Tennis</Link>
                            </li>
                            <li>
                                <Link to="/Basketball">Basketball</Link>
                            </li>
                        </ul>
                    </li>
                </ul>

            </div>

{/* NavButton */}
{/* <div className="NavButton">
sdfsdf
</div> */}


        </div>
    )
}

export default TopNavigation
