import React from 'react'
import './Social.css'
const Social = () => {
    return (
        <div className="social">
            <h1>social</h1>
        </div>
    )
}

export default Social
