import React from 'react'
import './Tennis.css'
const Tennis = () => {
    return (
        <div className="tennis">
            <h1>tennis</h1>
        </div>
    )
}

export default Tennis
