import React from 'react'
import './Result.css'
const Result = () => {
    return (
        <div className="result">
            <h1>result</h1>
        </div>
    )
}

export default Result
