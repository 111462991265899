import React from 'react'
import './Table.css'
const Tables = () => {
    return (
        <div className="table">
           <h1> table</h1>
        </div>
    )
}

export default Tables
