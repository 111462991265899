import React from 'react'
import './App.css';
import {Switch,Route} from 'react-router-dom'
import Home from './Components/Home/Home';
import Tickets from './Components/Tickets/Tickets';
import Tables from './Components/Tables/Tables';
import Social from './Components/Social/Social';
import Result from './Components/Result/Result';
import Fixtures from './Components/Fixtures/Fixtures';
import Soccer from './Components/Soccer/Soccer';
import Tennis from './Components/Tennis/Tennis';
import Basketball from './Components/Basketball/Basketball';
import TopNavigation from './Components/TopNavigation/TopNavigation';

function App() {
 
  return (
    <div className="App">
     <TopNavigation/>
<div style={{clear:'both'}}></div>

   


<Switch>


<Route exact path="/" component={Home}/>
<Route path="/Tickets" component={Tickets}/>
<Route path="/tables" component={Tables}/>
<Route path="/Social" component={Social}/>
<Route path="/Result" component={Result}/>
<Route path="/Fixtures" component={Fixtures}/>
<Route path="/Soccer" component={Soccer}/>
<Route path="/Tennis" component={Tennis}/>
<Route path="/Basketball" component={Basketball}/>
</Switch>





    </div>
  );
  
}

export default App;
