import React,{useState,useEffect} from 'react'
import './Soccer.css'
const Soccer = () => {
    const[open,setopen]=useState(true)
    const[data,setdata]=useState([])
    useEffect(()=>{
        fetch('https://restcountries.eu/rest/v2/all')
        .then(res=>res.json())
        .then(rsl=>setdata(rsl))
    },[])


   

    return (
        <div className="soccer">
           <h1>Search Your Club</h1>
          
           <select id="myselect" className={open ? 'myarrow' :'myclose'} onClick={()=>setopen(!open)}>
           <option>select your countries</option>

        {
            data.map(n=>{
                return(
                    <option>{n.name}</option>
                )
            })
        }
</select>
           
        </div>
    )
}

export default Soccer
