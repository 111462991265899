import React from 'react'
import './Ticket.css'
function Tickets() {
    return (
        <div className="ticket">
            <h1>tickets</h1>
        </div>
    )
}

export default Tickets
