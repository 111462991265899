import React from 'react'
import './Fixture.css'
const Fixtures = () => {
    return (
        <div className="Fixture">
            <h1>fixture</h1>
        </div>
    )
}

export default Fixtures
