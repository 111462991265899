import React from 'react'
import './Basketball.css'
const Basketball = () => {
    return (
        <div className="basketball">
            <h1>basketball</h1>
        </div>
    )
}

export default Basketball
